<template>
  <div class="SalarieModifyPassword" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Modification de votre mot de passe</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- Déjà connecté -->

            <div v-if="!isAuthenticated">
              <h2>
                Vous n'êtes pas connecté
              </h2>
              <div class="row text-center">
                <div class="col-12">
                  <contactComponent />
                </div>
              </div>

              <b-button
                block variant="outline-primary" class="mt-4"
                @click='goToLogin'>
                Connectez-vous
              </b-button>
            </div>

            <div v-else>
              <div v-if="passwordReseted">
                <div class="text-center">
                  <div>
                    <b-img :src="require('@/static/images/success.svg')" style="max-width: 5%;'"
                           fluid alt="Mot de passe modiifé"  />
                  </div>
                  <h2 class="mt-3">
                    Votre mot de passe a bien été modifié
                  </h2>
                </div>
                <div class="row mt-4">
                  <div class="col-12 text-center">
                    <button class="btn btn-primary" @click="goHome" style="white-space: normal;">
                      Retour à l'accueil
                    </button>
                  </div>
                </div>
              </div>
              <div v-else>

                <b-form v-on:submit.prevent="modifyPassword()" class="mt-4">
                  <b-form-group id="old-password-input" label="Votre mot de passe actuel" label-for="old-password-input">
                    <b-form-input
                      v-model="oldPassword" type="password"
                      maxlength="200"
                      id="old-password-input"
                      >
                    </b-form-input>
                    <small v-if="errorOldPasswordMessage" class="text-danger">Ce mot de passe ne correspond pas à votre mot de passe actuel, veuillez réessayer</small>
                  </b-form-group>
                  <b-form-group id="new-password-input" label="Votre nouveau mot de passe" label-for="new-password-input">
                    <b-form-input
                      v-model="newPassword" type="password"
                      maxlength="200"
                      id="new-password-input"
                      @input="$v.newPassword.$touch()"
                      :state="$v.newPassword.$dirty ? !$v.newPassword.$error : null">
                    </b-form-input>
                  </b-form-group>

                  <b-form-group id="new-password-confirmation-input" label="Confirmation de votre nouveau mot de passe" label-for="new-password-confirmation-input">
                    <b-form-input
                      v-model="newPasswordConfirmation" type="password"
                      maxlength="200"
                      id="new-password-confirmation-input"
                      @input="$v.newPasswordConfirmation.$touch()"
                      :state="$v.newPasswordConfirmation.$dirty ? !$v.newPasswordConfirmation.$error : null">
                    </b-form-input>
                    <small class="text-danger" v-if="!$v.newPasswordConfirmation.sameAsPassword">Veuillez rentrer deux mots de passe identiques</small>
                  </b-form-group>
                  <b-button
                    type="submit"
                    block :variant="modifyPasswordButtonStyle" class="mt-3"
                    @click='modifyPassword'
                    :disabled="$v.oldPassword.$invalid || $v.newPasswordConfirmation.$invalid || modifyPasswordInProcess">
                      <b-spinner small label="Confirm Reset Password Spinner" v-if="modifyPasswordInProcess"></b-spinner> {{ modifyPasswordButtonText }}
                  </b-button>
                </b-form>
              </div>
              <!-- ERROR MODAL -->
              <b-modal ref="GlobalAPIErrorModal" hide-footer title="Erreur globale">
                <div class="text-center">
                  <p>Une erreur s'est produite, veuillez réactualiser la page ou vous reconnecter</p>
                  <b-button class="mt-3" variant="outline-success" @click="hideGlobalAPIErrorModal">Fermer</b-button>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, sameAs } from 'vuelidate/lib/validators'
import contactComponent from '@/components/contactComponent'
import formTitleComponent from '@/components/formTitleComponent'

import { password_validation } from '@/validators/validators'

import { APISalarieConnected } from '@/api/APISalarieConnected'

const apiSalarieConnectedService = new APISalarieConnected()


export default {
  name: 'modifyPassword',
  components: {
    contactComponent,
    formTitleComponent
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      oldPassword: '',
      errorOldPasswordMessage: false,
      newPassword: '',
      newPasswordConfirmation: '',

      modifyPasswordButtonText: 'Réinitialiser votre mot de passe',
      modifyPasswordButtonStyle: 'outline-primary',
      modifyPasswordInProcess: false,

      passwordReseted: false
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.state.auth.token
    },
  },
  validations: {
    oldPassword: {
      required,
    },
    newPassword: {
      maxLength: maxLength(250),
      required,
      password_validation
    },
    newPasswordConfirmation: {
      maxLength: maxLength(250),
      required,
      sameAsPassword: sameAs('newPassword'),
    }
  },
  methods: {
    modifyPassword () {
      this.errorOldPasswordMessage = false
      this.modifyPasswordButtonText = 'Modification en cours'
      this.modifyPasswordButtonStyle = 'secondary'
      this.modifyPasswordInProcess = true
      apiSalarieConnectedService.resetSalariePassword(this.token, this.oldPassword, this.newPassword, this.newPasswordConfirmation)
      .then(() => {
          this.passwordReseted = true
        }, (error) => {
          if (error.response && error.response.data.old_password && error.response.data.old_password === "wrong_password") {
            this.errorOldPasswordMessage = true
          }
          else {
            this.showGlobalAPIErrorModal()
          }
        })
      .finally(() => {
        this.modifyPasswordButtonText = 'Réinitialiser votre mot de passe'
        this.modifyPasswordButtonStyle = 'outline-primary'
        this.modifyPasswordInProcess = false
      })
    },

    showGlobalAPIErrorModal () {
      this.$refs.GlobalAPIErrorModal.show()
    },
    hideGlobalAPIErrorModal () {
      this.$refs.GlobalAPIErrorModal.hide()
    },
    goToLogin () {
      this.$router.push('/login')
    },
    goHome () {
      this.$router.push('/')
    }
  }
}
</script>
